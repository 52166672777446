import React from 'react';




// Bien tansformer les parenthéses en accolades si on nclus des fonctions avant le return
const GameOver = () => {
  

    return (

        <p className ="textWhite"> DEFEAT...</p>
    );
}; 
 
export default GameOver;